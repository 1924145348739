import { useEffect } from 'react';
import { useQuery } from 'react-query';
import config from '../config';
import { useProductApi } from '../hooks/api';
import { VendorProductV2Response } from '../services/api/types';

export default function useVendorProducts({ vendorId }: { vendorId?: string }) {
  const productApi = useProductApi();
  const { data, refetch, isFetched, isFetching, isLoading } = useQuery<
    VendorProductV2Response,
    any
  >(
    [config.queryKeys.vendorProducts, vendorId],
    () => {
      return productApi.getVendorProducts(vendorId!);
    },
    {
      enabled: !!vendorId,
    }
  );
  useEffect(() => {
    if (!!vendorId) {
      refetch();
    }
  }, [vendorId]);
  return {
    isFetched,
    isFetching,
    isLoading,
    refetch,
    products: data?.products ?? [],
  };
}
