import React from 'react';
import { Icon, Image, Text } from '@goodfynd/react-web.lib.ui';
import type { IconName } from '@goodfynd/react-web.ui.icon';

import {
  StyledBenefitContainer,
  StyledBenefitsContainer,
  StyledContainer,
  StyledGraphicContainer,
} from './styles';
import type { CmsBenefitsProps } from './types';

export default function CmsBenefits({
  benefits = [],
  header,
  ...props
}: CmsBenefitsProps): JSX.Element {
  return (
    <StyledContainer {...props}>
      {!!header && (
        <Text as="heading5" style={{ marginTop: 24 }}>
          {header}
        </Text>
      )}

      <StyledBenefitsContainer {...props}>
        {benefits.map(({ description, icon, image }) => (
          <StyledBenefitContainer key={description}>
            {(!!icon || !!image) && (
              <StyledGraphicContainer>
                {!!icon && (
                  <Icon
                    color="TextIconFavorable"
                    name={icon.replace('icon-', '').trim() as IconName}
                    size={24}
                  />
                )}
                {!!image && (
                  <Image alt={description} height={24} src={image} width={24} />
                )}
              </StyledGraphicContainer>
            )}

            <Text>{description}</Text>
          </StyledBenefitContainer>
        ))}
      </StyledBenefitsContainer>
    </StyledContainer>
  );
}
