import { DependencyList, useEffect } from 'react';

import useTimeout from './useTimeout';

export default function useDebounce(
  callback: Callback,
  delay: number,
  deps: DependencyList
) {
  const { reset, stop } = useTimeout(callback, delay);
  useEffect(reset, [...deps, reset]);

  // Don't execute callback on first run
  useEffect(stop, []);
}
