import React from 'react';
import type {
  CmsContentProps,
  FaqsProps,
  StructuredColumnsProps,
  TwoColumnProps,
} from '@goodfynd/react-web.lib.content';
import {
  CmsContent,
  Faqs,
  StructuredColumns,
  TwoColumn,
} from '@goodfynd/react-web.lib.content';
import { ButtonLink } from '@goodfynd/react-web.ui.button';

import {
  CmsBenefits,
  CmsDashboardCta,
  CmsHelpSection,
} from '../../components/cms';
import type { CmsBenefitsProps } from '../../components/cms/CmsBenefits/types';

import type { ComponentProps, ComponentType } from './types';

export default function renderCmsBodyComponent(
  index: number,
  componentType: ComponentType,
  fields: unknown,
  { containerCSS }: ComponentProps = {}
): JSX.Element | null {
  switch (componentType) {
    case 'button':
      const { button_href, button_text } = fields as any;
      return !!button_href && !!button_text ? (
        <ButtonLink href={button_href}>{button_text}</ButtonLink>
      ) : null;

    case 'benefits':
      return (
        <CmsBenefits
          {...(fields as CmsBenefitsProps)}
          key={componentType + index}
        />
      );

    case 'content_section':
      return (
        <CmsContent
          {...(fields as CmsContentProps)}
          containerCSS={containerCSS}
          key={componentType + index}
        />
      );

    case 'content_with_image_columns':
      return (
        <StructuredColumns
          {...(fields as StructuredColumnsProps)}
          key={componentType + index}
        />
      );

    case 'dashboard_cta':
      return (
        <CmsDashboardCta
          {...(fields as CmsDashboardCtaProps)}
          key={componentType + index}
        />
      );

    case 'event_faqs':
      return <Faqs {...(fields as FaqsProps)} key={componentType + index} />;

    case 'help_section':
      return (
        <CmsHelpSection
          {...(fields as CmsHelpSectionProps)}
          key={componentType + index}
        />
      );

    case 'event_help_section':
      return (
        <CmsHelpSection
          {...(fields as CmsHelpSectionProps)}
          key={componentType + index}
        />
      );

    case 'vendor_help_section':
      return (
        <CmsHelpSection
          {...(fields as CmsHelpSectionProps)}
          key={componentType + index}
        />
      );

    case 'two_column_images':
      return (
        <TwoColumn
          {...(fields as TwoColumnProps)}
          key={componentType + index}
        />
      );

    case 'vendor_faqs':
      return <Faqs {...(fields as FaqsProps)} key={componentType + index} />;

    default:
      return null;
  }
}
