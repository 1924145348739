import { Container } from '@goodfynd/react-web.layout.container';
import { Link, Text } from '@goodfynd/react-web.lib.ui';

import { StyledContainer, StyledContent } from './styles';

import classes from './CmsHelpSection.module.css';

export default function CmsHelpSection({
  title,
  topics = [],
  ...props
}: CmsHelpSectionProps) {
  return (
    <Container
      css={{
        display: 'flex',
        gap: 24,
        flexDirection: 'column',
        paddingBottom: 24,
        paddingTop: 24,
      }}
    >
      {!!title && <Text as="heading3">{title}</Text>}

      <StyledContainer {...props}>
        {topics.map(({ featured_articles, description, title = '' }, index) => (
          <StyledContent key={title + index}>
            {!!title && (
              <Text as="heading5" className={classes['topic-title']}>
                {title}
              </Text>
            )}

            {!!description && (
              <Text className={classes['topic-description']} truncate={2}>
                {description}
              </Text>
            )}

            <ul>
              {featured_articles.map(({ name, slug }) => (
                <li key={slug}>
                  <Link
                    href={slug?.startsWith('http') ? slug : `/docs/${slug}`}
                  >
                    {name}
                  </Link>
                </li>
              ))}
            </ul>
          </StyledContent>
        ))}
      </StyledContainer>
    </Container>
  );
}
