import { useQuery } from 'react-query';

import config from '../config';
import { useEventApi } from '../hooks/api';

export default function useEvent(id?: string) {
  const eventApi = useEventApi();
  const { data, isFetched, isFetching, isLoading, refetch } = useQuery(
    [config.queryKeys.event, id],
    () => {
      return eventApi.get(id as string);
    },
    {
      enabled: !!id,
    }
  );

  return {
    isFetched,
    isFetching,
    isLoading,
    refetch,
    event: data?.entry,
  };
}
