import { useRef } from 'react';

export default function useLastValue<T>(value: T) {
  const currentRef = useRef(value);
  const lastRef = useRef<T>();

  if (currentRef.current !== value) {
    lastRef.current = currentRef.current;
    currentRef.current = value;
  }

  return lastRef.current;
}
