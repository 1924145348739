import React from 'react';
import type {
  PageTitleProps,
  TutorialHeaderProps,
} from '@goodfynd/react-web.lib.content';
import { PageTitle, TutorialHeader } from '@goodfynd/react-web.lib.content';

import type { ComponentType } from './types';

export default function renderCmsTitleComponent(
  index: number,
  componentType: ComponentType,
  fields: Record<string, unknown>
): unknown {
  switch (componentType) {
    case 'page_title':
      return (
        <PageTitle
          {...(fields as unknown as PageTitleProps)}
          key={componentType + index}
        />
      );

    case 'tutorial_header':
      return (
        <TutorialHeader
          {...(fields as unknown as TutorialHeaderProps)}
          key={componentType + index}
        />
      );

    default:
      return null;
  }
}
