import { useQuery } from 'react-query';

import { minutesToMS } from '@goodfynd/react-web.utils.number-util';

import config from '../config';
import { useVendorApi } from '../hooks/api';

import type { VendorResponse } from '../services/api/types';
import type {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query';
import type { VendorItem } from '../types/shared';
interface VendorOptions {
  enabled?: boolean;
  id: string;
  track?: boolean;
}

interface VendorReturnValue {
  isFetched: boolean;
  isLoading: boolean;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<VendorResponse, unknown>>;
  vendor?: VendorItem;
}
export function useVendor({ enabled, id }: VendorOptions): VendorReturnValue {
  const api = useVendorApi();
  const { data, isFetched, isLoading, refetch } = useQuery<
    VendorResponse,
    unknown,
    VendorResponse
  >(
    config.queryKeys.vendor,
    () => {
      return api.get(id as string);
    },
    {
      enabled,
      staleTime: minutesToMS(3),
    }
  );

  return { isFetched, isLoading, refetch, vendor: data?.vendor };
}
export default useVendor;
