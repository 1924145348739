import { useCallback, useEffect, useRef } from 'react';

export default function useTimeout(callback: Callback, delay: number) {
  const callbackRef = useRef<Callback>(callback);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const start = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const stop = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    start();
    return stop;
  }, [delay, start, stop]);

  const reset = useCallback(() => {
    stop();
    start();
  }, [stop, start]);

  return { reset, stop };
}
