import { styled } from '@goodfynd/react-web.theme';
import Text from '@goodfynd/react-web.typography.text';

export const StyledContainer = styled('section', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '32px 24px',
  '@mdMax': {
    gap: 24,
  },
});

export const StyledContent = styled('div', {
  border: '1px solid $Neutral05',
  borderRadius: 6,
  flexGrow: 1,
  padding: 24,
  '@lgMin': {
    maxWidth: 586,
  },
  '@mdMax': {
    flexBasis: '100%',
  },
});

export const StyledSectionTitle = styled(Text, {
  fontSizeRem: 32,
  lineHeight: 40 / 32,
});
